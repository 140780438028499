.root
  padding: 32px 32px 80px
  background-color: $light-grey
  text-align: center

.row
  display: grid
  grid-gap: 32px

.appsInfo
  display: flex
  align-items: center
  justify-content: center

.qr
  display: none

.appsInfoTitle
  font-size: 16px
  font-weight: 500
  line-height: 24px

.appsInfoDescription
  margin-top: 8px
  font-size: 14px
  line-height: 18px

.storeList
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  gap: 8px
  margin-top: 16px

.storeLink.storeLink
  display: block
  font-size: 0
  border-radius: 8px
  overflow: hidden

.contacts
  margin-top: 24px

.linksItem
  & + &
    margin-top: 8px

.socialTitle
  font-size: 14px
  line-height: 18px

.socialList
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap
  gap: 16px
  margin-top: 16px

.socialLink.socialLink
  display: block
  font-size: 0
  width: 24px
  height: 24px
  background-size: 100%
  background-repeat: no-repeat
  transition: background-image 200ms

.socialLinkTypeFacebook
  background-image: url('./images/facebook.png')

  &:hover
    background-image: url('./images/facebook-colored.png')

.socialLinkTypeInstagram
  background-image: url('./images/instagram.png')

  &:hover
    background-image: url('./images/instagram-colored.png')

.socialLinkTypeYoutube
  background-image: url('./images/youtube.png')

  &:hover
    background-image: url('./images/youtube-colored.png')

.copyrightSection
  margin-top: 42px

.copyright
  margin-top: 4px
  font-size: 14px
  font-weight: 500
  line-height: 18px

@media ($min-desktop)
  .root
    padding: 40px 0 24px
    text-align: left

  .row
    grid-template-columns: repeat(4, auto)
    grid-gap: 100px
  
  .appsInfo
    justify-content: flex-start
    max-width: 315px

  .qr
    display: block
    margin-right: 24px

  .storeList
    justify-content: flex-start

  .socialList
    justify-content: flex-start
